import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractEntityGridColumnComponent } from '@entity-framework/entity-grid/state/abstract-entity-grid-column.component';
import { BureauPaymentListItem } from '@app/bureau/payments/models/bureau-payment-list-item';
import { formatDate } from '@utils/date-utils';

@Component({
  selector: 'app-bureau-payments-authorised-date-column',
  template: `
    @if (listItem.authorisedDate) {
      <app-body-sm
        ><span>{{ getFormattedDate() }}</span></app-body-sm
      >
    } @else {
      <app-body-xs [italic]="true" [ink]="'ink-45'"><span>Expecting before</span></app-body-xs>
      <br />
      <app-body-sm [italic]="true" [ink]="'ink-45'"
        ><span>{{ getFormattedExpectedDate() }}</span></app-body-sm
      >
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class BureauPaymentsAuthorisedDateColumnComponent extends AbstractEntityGridColumnComponent<BureauPaymentListItem> {
  protected getFormattedDate(): string {
    return formatDate(this.listItem.authorisedDate, 'DD MMM YYYY, HH:mm');
  }

  protected getFormattedExpectedDate(): string {
    const expectedDate = new Date(this.listItem.submissionDate);
    expectedDate.setDate(expectedDate.getDate() - 1);
    return formatDate(expectedDate, 'DD MMM YYYY, 15:00');
  }
}
