import { GridColumn, PropertyType } from '@entity-framework/entity-grid/state/entity-grid-column';
import { formatDate, isOverdue, isToday } from '@utils/date-utils';
import { BankFileStatusType } from '@app/bureau/bank-files/models/bureau-bank-file-status-type';
import { BureauPaymentsStatusColumnComponent } from '@app/bureau/payments/entity-grid/columns/bureau-payments-status-column.component';
import { BureauPaymentListItem } from '@app/bureau/payments/models/bureau-payment-list-item';
import { BureauPaymentsAuthorisedDateColumnComponent } from '@app/bureau/payments/entity-grid/columns/bureau-payments-authorised-date-column.component';
import { BureauProfile } from '@app/bureau/bureau-profile';
import { BureauPaymentsHmrcSubmissionColumnComponent } from '@app/bureau/payments/entity-grid/columns/bureau-payments-hmrc-submission-column.component';
import { BureauPaymentsTeamNameGridColumnComponent } from '@app/bureau/payments/entity-grid/columns/bureau-payments-team-name-grid-column.component';
import { BureauPaymentsAuthorisedAmountColumnComponent } from './bureau-payments-authorised-amount-column-component';

const defaults = {
  propertyType: 'text' as PropertyType,
  sortable: false,
  tdCssStyleClass: (value) => getBackgroundColour(value)
};

export function getBureauPaymentsGridColumns(bureau: BureauProfile, forAdvancedView: boolean = false): GridColumn[] {
  var columns = [
    {
      propertyName: 'status',
      headerLabel: 'Status',
      sortable: false,
      propertyType: 'component',
      componentType: BureauPaymentsStatusColumnComponent,
      tdCssStyleClass: (value) => getBackgroundColour(value),
      formatForDisplay: (value) => (value === 'NotAuthorised' ? 'Not authorised' : value),
      formatForFilter: (value) => (value === 'NotAuthorised' ? 'Not authorised' : value),
      tdCssClass: 'status-column',
      thCssClass: 'status-column'
    },
    {
      propertyName: 'submissionDate',
      headerLabel: 'Submission date',
      formatForDisplay: (value) => formatDate(value, 'DD MMM YYYY'),
      textSemiBold: (value) => isOverdueWithStatusCheck(value),
      textInk: (value) => (isOverdueWithStatusCheck(value) ? 'system-red' : 'ink-85'),
      ...defaults
    },
    {
      propertyName: 'paymentDate',
      headerLabel: 'Pay date',
      formatForDisplay: (value) => formatDate(value, 'DD MMM YYYY'),
      textSemiBold: (value) => isOverdueWithStatusCheck(value),
      textInk: (value) => (isOverdueWithStatusCheck(value) ? 'system-red' : 'ink-85'),
      ...defaults
    },
    {
      propertyName: 'organisationName',
      headerLabel: 'Organisation',
      propertyType: 'elementLink',
      sortable: false,
      tdCssStyleClass: (value) => getBackgroundColour(value)
    },
    {
      propertyName: 'payePayroll',
      headerLabel: 'PAYE / Payroll',
      ...defaults
    },
    { propertyName: 'paymentType', headerLabel: 'Type', ...defaults },
    {
      propertyName: 'totalAmount',
      headerLabel: 'Authorised amount',
      propertyType: 'component',
      componentType: BureauPaymentsAuthorisedAmountColumnComponent,
      tdCssStyleClass: (value) => getBackgroundColour(value),
      alignRight: true,
      textSemiBold: (value) => true
    }
  ] as GridColumn[];

  if (forAdvancedView) {
    columns.push({
      sortable: false,
      propertyName: 'authorisedBy',
      propertyType: 'text',
      headerLabel: 'Who authorised'
    } as GridColumn);
  }

  columns.push({
    sortable: false,
    propertyName: 'authorisedDate',
    propertyType: 'component',
    formatForDisplay: (value) => formatDate(value, 'DD MMM YYYY, HH:mm'),
    componentType: BureauPaymentsAuthorisedDateColumnComponent,
    headerLabel: 'Authorised date & time',
    tdCssStyleClass: (value) => getBackgroundColour(value)
  } as GridColumn);

  columns.push({
    propertyName: 'hasHmrcSubmission',
    headerLabel: 'FPS/EPS sub',
    sortable: false,
    propertyType: 'component',
    componentType: BureauPaymentsHmrcSubmissionColumnComponent,
    tdCssStyleClass: (value) => getBackgroundColour(value),
    alignCenter: true
  } as GridColumn);

  if (bureau.teamsEnabled) {
    columns.push({
      propertyName: 'teams',
      headerLabel: 'Team(s)',
      ...defaults,
      sortable: false,
      propertyType: 'component',
      componentType: BureauPaymentsTeamNameGridColumnComponent,
      tdCssStyleClass: (value) => getBackgroundColour(value)
    } as GridColumn);
  }

  if (forAdvancedView) {
    columns.push({
      sortable: false,
      propertyName: 'paymentRunId',
      propertyType: 'text',
      headerLabel: 'Payment Run Id'
    } as GridColumn);

    columns.push({
      sortable: false,
      propertyName: 'payrollGuid',
      propertyType: 'text',
      headerLabel: 'Payroll GUID'
    } as GridColumn);

    columns.push({
      sortable: true,
      propertyName: 'cancellationNotes',
      propertyType: 'text',
      headerLabel: 'Dismissal Notes'
    } as GridColumn);
  }

  return columns;
}

function getBackgroundColour(value: BureauPaymentListItem): string {
  if (statusToModifyStyling(value)) {
    return isToday(value.submissionDate)
      ? 'col-bg-system-blue-light-05-opacity'
      : isOverdue(value.submissionDate)
        ? 'col-bg-system-red-light-05-opacity'
        : '';
  }
  return '';
}

function isOverdueWithStatusCheck(value: BureauPaymentListItem): boolean {
  return statusToModifyStyling(value) && isOverdue(value.submissionDate);
}

function statusToModifyStyling(value: BureauPaymentListItem): boolean {
  return value?.status === BankFileStatusType.NEW || value?.status === BankFileStatusType.DOWNLOADED;
}
