import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractEntityGridColumnComponent } from '@entity-framework/entity-grid/state/abstract-entity-grid-column.component';
import { BureauPaymentListItem } from '@app/bureau/payments/models/bureau-payment-list-item';
import { columnFormatters } from '@utils/display-formatters';

@Component({
  selector: 'app-bureau-payments-authorised-amount-column',
  template: `
    @if (isEmptyTotalAmount()) {
      <div class="variance-container">
        <span class="no-variance spanalign"> </span>
      </div>
    } @else {
      {{ formattedValue() }}
    }
  `,
  styleUrls: ['../../../../../framework/design-system/components/variance-pill/variance-pill.component.scss'],
  styles: [
    `
      .spanalign {
        float: right;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BureauPaymentsAuthorisedAmountColumnComponent extends AbstractEntityGridColumnComponent<BureauPaymentListItem> {
  isEmptyTotalAmount = (): boolean => {
    return this.listItem?.totalAmount === 0 || this.listItem?.totalAmount == null;
  };
  formattedValue = (): string => {
    return columnFormatters.currency(this.listItem.totalAmount);
  };
}
